import { IRetailer } from '~/types/retailer'

export type MapOptions = {
  scale: number
  size: string
  zoom: number
}

export const useRetailerMap = (
  retailer: IRetailer,
  options?: Partial<MapOptions>
) => {
  const { $api, $config } = useNuxtApp()
  const { retailerAddress } = useRetailer(toRef(retailer))

  const o: MapOptions = {
    scale: 2,
    size: '398x166',
    zoom: 14,
    ...options
  }

  const iconUrl = $config.public.baseUrl + '/images/map/map-marker@2x.png'

  const mapString = computed<string>(() =>
    encodeURI(
      `?center=${retailerAddress.value}&zoom=${o.zoom}&size=${o.size}&scale=${o.scale}&markers=anchor:24,48|scale:${o.scale}|icon:${iconUrl}|${retailerAddress.value}`
    )
  )

  const mapKey = computed(
    () => `maps/${retailerAddress.value}-${o.size}-${o.scale}-${o.zoom}`
  )

  const { data: map, pending } = useCustomAsyncData(
    mapKey.value,
    () => $api.map.getImage(mapString.value),
    { watch: [retailerAddress] }
  )

  const googleMapUrl = computed<string>(
    () =>
      `https://www.google.com/maps/search/${encodeURI(retailerAddress.value)}/`
  )

  return {
    map,
    pending,
    googleMapUrl
  }
}
