<template>
  <div class="mt-2">
    <div class="opening-times">
      <div class="opening-times__current-status">
        <span class="text-base text-gray-500">
          Currently {{ isOpen ? 'open' : 'closed' }}
        </span>
        <p class="bold text-lg text-gray-900">
          <span v-if="isOpen"
            >{{ currentDay.OpenFrom }} - {{ currentDay.OpenTo }}</span
          >
          <span v-else class="bold text-lg text-gray-900">
            Opens {{ opensAt }}
          </span>
        </p>
      </div>
      <OsButton
        data-testid="toggle"
        class="opening-times--open inline-button"
        aria-label="More information"
        @click="toggleOpeningHours"
      >
        <OsIcon name="More" />
      </OsButton>
    </div>
    <div v-if="isExpanded">
      <p class="bold text-gray-900 opening-times__title">Opening times</p>

      <div class="opening-times__expanded">
        <div class="opening-times__table">
          <p
            v-for="(time, index) in reorderedDays"
            :key="index"
            :class="{
              bold: time.Day === today,
              'closed-text': !time.OpenFrom || !time.OpenTo
            }"
            class="opening-times__data"
          >
            {{ time.Day }}
          </p>
        </div>

        <div class="opening-times__table">
          <p
            v-for="(time, index) in reorderedDays"
            :key="index"
            :class="{
              bold: time.Day === today,
              'closed-text': !time.OpenFrom || !time.OpenTo
            }"
            class="opening-times__data"
          >
            <span v-if="time.OpenFrom && time.OpenTo">
              {{ time.OpenFrom }} - {{ time.OpenTo }}</span
            >
            <span v-else> Closed </span>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent, computed, PropType } from 'vue'
import dayjs from 'dayjs'
import isBetween from 'dayjs/plugin/isBetween'
import { IRetailer, Day } from '~/types/retailer'
import { ERouteConditions } from '~/types/routes'
import { NewVehicle, UsedVehicle } from '~/types/vehicle'

// eslint-disable-next-line import/no-named-as-default-member
dayjs.extend(isBetween)

export default defineComponent({
  name: 'RetailerOpeningTimes',
  props: {
    retailer: {
      type: Object as PropType<IRetailer>,
      required: true
    },
    vehicle: {
      type: Object as PropType<NewVehicle | UsedVehicle>,
      required: false,
      default: null
    },
    pageCategory: {
      type: String,
      required: false,
      default: ''
    }
  },
  setup(props) {
    const { $dataLayer } = useNuxtApp()
    const [isExpanded, toggle] = useToggle(false)
    const route = useRoute()

    const toggleOpeningHours = () => {
      toggle()

      $dataLayer.linkClick({
        pageCategory: props.pageCategory,
        category: 'product',
        action: `retailer: open time ${isExpanded.value ? 'expand' : 'collapse'}`
      })
    }

    const today = computed(() => dayjs().format('dddd'))

    const pageCondition = computed<ERouteConditions>(() => {
      return (
        (route.params.condition as ERouteConditions) || ERouteConditions.New
      )
    })

    const openingTimes = computed(() => {
      const daysOfWeek = [
        'Monday',
        'Tuesday',
        'Wednesday',
        'Thursday',
        'Friday',
        'Saturday',
        'Sunday'
      ]

      let openingTimes: Day[] = []

      if (props.vehicle) {
        const isNew = props.vehicle.VehicleType === 'NEW'
        openingTimes = props.retailer.OpeningTimes![isNew ? 'New' : 'Used']
      } else {
        openingTimes =
          props.retailer.OpeningTimes![
            pageCondition.value === ERouteConditions.New ? 'New' : 'Used'
          ]
      }

      if (openingTimes.length === 0) {
        openingTimes = props.retailer.OpeningTimes!.New
      }

      return daysOfWeek.map((day) => {
        const d = openingTimes.find(({ Day }) => Day === day)

        return {
          Day: day,
          OpenFrom: d?.OpenFrom ?? undefined,
          OpenTo: d?.OpenTo ?? undefined
        }
      })
    })

    const reorderedDays = computed<Day[]>(() => {
      const startIndex = openingTimes.value.findIndex(
        ({ Day }) => Day === today.value
      )

      if (startIndex === -1) {
        return openingTimes.value
      }

      const reorderedDays = []
      const days = openingTimes.value

      for (let i = 0; i < days.length; i++) {
        const index = (startIndex + i) % days.length
        reorderedDays.push(days[index])
      }

      return reorderedDays
    })

    // currentDay
    const currentDay = computed<Day>(() => {
      return openingTimes.value.find(({ Day }) => Day === today.value)!
    })

    // isOpen
    const isOpen = computed(() => isOpenNow(currentDay.value))

    const isOpenNow = ({ OpenFrom, OpenTo }: Day) => {
      if (!OpenFrom || !OpenTo) return false

      const openFrom = dayjs()
        .hour(Number(OpenFrom.slice(0, 2)))
        .minute(Number(OpenFrom.slice(3, 5)))

      const openTo = dayjs()
        .hour(Number(OpenTo.slice(0, 2)))
        .minute(Number(OpenTo.slice(3, 5)))

      return dayjs().isBetween(openFrom, openTo)
    }

    const opensAt = computed(() => {
      const openDays = reorderedDays.value.filter(({ OpenFrom }) => !!OpenFrom)

      const todaysOpenTime = reorderedDays.value[0].OpenFrom

      if (!todaysOpenTime) return openDays[0].OpenFrom

      const isBeforeOpening = dayjs().isBefore(
        dayjs()
          .hour(Number(todaysOpenTime.slice(0, 2)))
          .minute(Number(todaysOpenTime.slice(3, 5)))
      )

      return isBeforeOpening ? todaysOpenTime : openDays[1].OpenFrom
    })

    return {
      isExpanded,
      toggleOpeningHours,
      isOpen,
      opensAt,
      today,
      currentDay,
      reorderedDays
    }
  }
})
</script>

<style lang="scss" scoped>
.opening-times {
  display: flex;
  justify-content: space-between;
  position: relative;

  &__current-status {
    display: flex;
    flex-direction: column;
  }

  &--open {
    position: absolute;
    bottom: 0;
    right: 0;
  }

  &__expanded {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
  }

  &__title {
    margin-top: 16px;
    line-height: 1;
  }

  &__title::before {
    display: block;
    height: 2px;
    width: 50%;
    background-color: $grey--light;
  }

  &__table {
    margin-top: 16px;
  }

  &__data {
    line-height: 1;
  }

  &__data:not(:last-child) {
    margin-bottom: 12px;
    font-variant-numeric: tabular-nums;
  }

  &__data.closed-text {
    color: $grey--darkest;
  }
}

.opening-times__title::before {
  content: '';
  display: block;
  height: 2px;
  width: 15%;
  background-color: $grey--light;
  margin-bottom: 16px;
}
</style>
